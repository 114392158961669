body {
    margin: 0;
    padding: 0;
}

.dark {
    background-color: #0c4bb1;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    /*background: linear-gradient(90deg, rgba(38,145,217,1) 0%, rgba(0,202,255,1) 50%, rgba(38,145,217,1) 100%);*/
    height: 100vh;
    overflow: hidden;
}