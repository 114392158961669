* {
    margin: 0;
    padding: 0;
}

.material-icons {
    font-size: 28px;
}

body {
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.sidebar {
    position: fixed;
    width: 78px;
    height: 100%;
    display: block;
    background-color: #0c4bb1;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    /*background-color: #1f1b24;*/
    padding: 6px 14px;
    transition: all 0.5s ease;
}

.sidebar.active {
    width: 224px;
}

.sidebar .logo .logo-content {
    color: #eee6ff;
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    opacity: 0;
    pointer-events: none;
}

.sidebar.active .logo .logo-content {
    opacity: 1;
    pointer-events: none;
}

.sidebar .logo i {
    font-size: 28px;
    margin-right: 5px;
}

.sidebar .logo .menu {
    position: absolute;
    color: #eee6ff;
    left: 50%;
    top: 6px;
    font-size: 32px;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    transform: translateX(-50%);
    cursor: pointer;
    transition: all 0.3s ease;
}

.sidebar.active .menu {
    left: 90%;
}

.sidebar .logo .logo-name {
    font-size: 36px;
    font-weight: 400;
    pointer-events: auto;
}

.sidebar ul {
    margin-top: 20px;
}

.sidebar ul li {
    position: relative;
    height: 50px;
    min-width: 50px;
    margin: 0 5px;
    list-style: none;
    line-height: 50px;
    align-items: center;
}

.sidebar ul li a{
    color: #eee6ff;
    display: flex;
    flex-direction: column;
    align-content: center;
    text-decoration: none;
    transition: all 0.2s ease;
    border-radius: 12px;
}

.sidebar.active ul li a {
    flex-direction: row;
}

.sidebar ul li a:hover {
    background-color: #eee6ff;
    color: #1f1b24;
}

.sidebar ul li a:active {
    background-color: #eee6ff;
    color: #1f1b24;
}

.sidebar ul li a i {
    height: 50px;
    min-width: 50px;
    border-radius: 12px;
    line-height: 50px;
    text-align: center;
}

.sidebar .link-name {
    display: none;
    opacity: 0;
    pointer-events: auto;
}

.sidebar.active .link-name {
    display: block;
    opacity: 1;
    font-size: 20px;
    pointer-events: auto;
}

.sidebar .profile-content {
    left: 0;
    bottom: 0;
    position: absolute;
    color: #eee6ff;
    width: 100%;
    border-top: 2px solid white;
}

.sidebar .profile-content .profile {
    /*position: relative;*/
    /*padding: 10px 6px;*/
    /*height: 60px;*/
}

/*.sidebar .profile-content .profile .profile-details {*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/


.sidebar .profile-content .profile .profile-details .profile-image {
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 12px;
}


/*.sidebar .profile-content .profile .profile-details .profile-info {*/
/*    margin-left: 10px;*/
/*}*/

/*.sidebar .profile-content .profile .profile-details .profile-info .name {*/
/*    font-size: 18px;*/
/*    font-weight: 400;*/
/*}*/

/*.sidebar .profile-content .profile .profile-details .profile-info .job-position {*/
/*    font-size: 16px;*/
/*}*/

/*.sidebar .profile-content .profile .logout {*/
/*    color: #eee6ff;*/
/*    position: absolute;*/
/*    left: 50%;*/
/*    bottom: 30px;*/
/*    transform: translateX(-50%);*/
/*}*/

.sidebar .profile-content .profile .logout:hover {
    color: #1f1b24;
    cursor:pointer;
}


.sidebar .profile-image {
    display: none;
}

.sidebar.active .profile-image  {
    display: block;
}

.sidebar .profile-info {
    display: none;
}

.sidebar.active .profile-info {
    display: block;
}

.main-content{
    position: relative;
    background: #E4E9F7;
    min-height: 100vh;
    top: 0;
    left: 100px;
    width: calc(100% - 120px);
    transition: all 0.5s ease;
    z-index: 2;
    padding: 10px;
}
.sidebar.active ~ .main-content{
    left: 252px;
    width: calc(100% - 275px);
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 20px;
}

.grid-container > div {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px 0;
}

.card {
    position: relative;
    box-shadow: 1px 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.card .card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
    font-size: 30px
}

@media (max-width: 480px) {
    .grid-container {
        grid-template-columns: auto;
    }
}

@media (min-width: 480px) {
    .grid-container {
        grid-template-columns: auto;
    }
}
@media (min-width: 768px) {
    .grid-container {
        grid-template-columns: auto auto;
    }
}
@media (min-width: 1024px) {
    .grid-container {
        grid-template-columns: auto auto auto auto;
    }
}
@media (min-width: 1200px) {
    .grid-container {
        grid-template-columns: auto auto auto auto;
    }
}
