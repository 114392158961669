.card {
    border-radius: 10px;
}

.card .card-title {
    text-align: center;
    padding: 20px 0 20px 0;
    border-bottom: 1px solid silver;
}

.card .card-body {
    padding: 0 40px;
}

.card .card-text-center {
    text-align: center;
}