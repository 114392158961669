.link {
    margin: 30px 0 30px 0;
    text-align: center;
    font-size: 18px;
    color: #666666;
}

.link a {
    color: rgba(38,145,217,1);
    text-decoration: none;
}

.link a:hover {
    text-decoration: underline;
}