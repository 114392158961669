.btn_radius {
    width: 100%;
    height: 50px;
    border: 1px solid;
    border-radius: 25px;
    background-color: rgba(38,145,217,1);
    color: #eee6ff;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    outline: none;
}

.btn_radius:hover {
    border-color: rgba(38,145,217,1);
    transition: 0.5s;
}